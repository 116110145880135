.selectedBackground {
    background-color: #d5e7f9;
    font-size: 1rem;
    background-size: 100%;
    display: inline-block;
    width: 100%;
    border-bottom: #f2f2f2 solid 1px;
}

.unselectedBackground {
    background-color: #f2f2f2;
    font-size: 1rem;
    display: inline-block;
    width: 100%;
    border-bottom: #e0e0e0 solid 1px;
}

.custom-checkbox {
    margin-left: 5px;
}
