// In order to use bootstrap's style and functions
// We need to import these three files
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

.markdown-body {
    margin-left: 30px;
    margin-top: 30px;
    @include media-breakpoint-down(md) {
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 10px;
    }
    padding-bottom: 100px;
    img {
        max-width: 800px;
        display: block;
    }

    h1 {
        display: none !important;
    }

    h2 {
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
        margin-top: 30px;
    }

    h3 {
        font-size: 18px;
    }
}
