.bg-mskcc-header {
    background-color: #444;
    color: #ffffff;
    font-size: 1rem;
    text-shadow: 0 0 4px black;
    border-bottom: 1px solid black;
}

.brand-title-link,
.brand-title-link:hover,
.brand-title-link:focus,
.brand-title-link:active,
.brand-title-link:visited {
    text-decoration: none;
    color: #ffffff;
    font-size: 1.5rem;
    font-family: Futura, sans-serif;
    display: flex;
    align-items: center;
}

.brand-title-link img {
    height: 27px;
}

.nav-link {
    color: #fff !important;
}
