body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', Georgia, sans-serif, serif;
}

h1,
h2,
h3,
h4,
h5 {
    color: #49a8e5;
}

.rc-tooltip {
    opacity: 1;
}

.page-section {
    padding-bottom: 40px;
    h4 {
        margin-bottom: 20px;
    }

    &:first-child {
        margin-top: 25px;
    }
}

.page-body {
    font-size: 0.8rem;
}

.Main {
    position: relative;
    min-height: 100vh;
}

.mskcc-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 5px 20px;

    a {
        padding: 2px 10px;
    }
}

.borderedChart {
    border: 1px dashed #ddd;
    padding: 20px;
    border-radius: 4px;
}

.loadingIndicator {
    position: fixed;
    top: 50%;
    left: 50%;
}

.cursor-pointer {
    cursor: pointer;
}
