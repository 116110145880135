// In order to use bootstrap's style and functions
// We need to import these three files
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

#home-logo {
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2%;
    max-width: 700px;
    color: #7b7979;
    text-align: center;
}

#home-logo h2 {
    font-size: 80px;
    font-family: Georgia;
    color: darkolivegreen;
    text-shadow: 1px 1px 1px #999;
}

#home-example-container {
    background-color: #f2f2f2;
    height: fit-content;
    margin-left: -2rem;
    margin-right: -2rem;
    margin-top: 7rem;
}

#home-function-description {
    font-size: 1rem;
    color: #828282;
    margin: 3rem auto 3rem;
    font-style: italic;
}

#home-query-example-header {
    margin: 8rem auto 3rem;
    font-size: 1.625rem;
    padding-left: 20%;
}

#home-query-example-table {
    margin: auto auto 8rem;
    font-size: 1rem;
    max-width: 1600px;
}

#search-box-container {
    font-size: 1.1rem;
    max-width: 600px;
}

.home-banner {
    text-align: center;
    margin-bottom: 40px;

    h2 {
        font-family: Futura, sans-serif;
        font-size: 100px;
        text-shadow: 0 0 4px #ccc;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        div {
            white-space: pre-wrap;
        }
    }
}

.validator-notification {
    a {
        width: max-content;
    }
    tr:first-child td {
        border-top: none;
    }
    tr:last-child td {
        border-bottom: 1px solid #dee2e6;
    }
}

.tooltip-close-button {
    margin-top: 8px;
    margin-right: 8px;
    float: right;
}

.space {
    @include media-breakpoint-down(md) {
        display: none;
    }
}
