// In order to use bootstrap's style and functions
// We need to import these three files
@import 'bootstrap/scss/_functions';
@import 'bootstrap/scss/_variables';
@import 'bootstrap/scss/mixins/_breakpoints';

.group-name {
    background-color: #f2f2f2;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-bottom: 4px;
    padding-top: 4px;
    max-width: 27%;
    display: flex;
    align-items: center;
}

.group-content {
    display: flex;
    align-items: center;
}

.data-content {
    margin-top: 3px;
    margin-bottom: 3px;
    padding-bottom: 4px;
    padding-top: 4px;
}

.data-group-gap {
    display: block;
}

.no-data {
    font-size: 1rem;
    margin-left: 15px;
    color: black;
}

/* gnomad */
.gnomad {
    font-size: 1rem;
    font-weight: bold;
}

/* dbsnp */
.dbsnp {
    font-size: 1rem;
    font-weight: bold;
}

/* functional prediction */
.functional-prediction-data {
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.functional-prediction-no-data {
    font-size: 1rem;
    font-weight: bold;
}

// oncokb
.oncokb {
    font-size: 1rem;
    font-weight: bold;
}

// redesign from here. remove some of classes above after complete
.functional-groups {
    padding-top: 10px;

    p {
        margin-bottom: 5px;
    }
    p:last-child {
        margin-bottom: 0;
    }
}

.data-source {
    min-width: 90px;
    @include media-breakpoint-up(lg) {
        min-width: 200px;
    }
}

.functional-group {
    display: flex;
}

.data-table {
    width: 100%;
}

.frequency-table {
    padding-top: 10px;
}

.data-with-link {
    a {
        color: black;
        &:hover,
        &:focus,
        &:active,
        &:visited {
            color: black;
        }
    }
}

.without-linkout {
    color: #007bff;
    &:hover,
    &:focus,
    &:active,
    &:visited {
        color: #007bff;
        cursor: pointer;
        text-decoration: underline;
    }
}
