/*.transcript-table {*/
/*    font-size: 1rem;*/
/*}*/

.table-content {
    margin-top: 1rem;
}

.table-content .table-responsive .table {
    margin-bottom: 3px;
}

.transcript-link {
    font-size: 12.8px !important;
    padding: 0px !important;
}
