@import 'functionalImpact';

.high {
    color: $high;
}

.medium {
    color: $medium;
}

.low {
    color: $low;
}

.neutral {
    color: $neutral;
}

.unknown {
    display: none;
}
