.default-data-table {
    font-size: 0.8rem;
    min-width: 300px;

    .rt-table .rt-tbody .rt-td {
        padding: 5px 5px;
    }

    .-pagination {
        font-size: 0.7rem;
        padding: 2px;

        .-center {
            flex-grow: 3 !important;
        }

        .-pageJump {
            input {
                padding: 2px;
            }
        }

        .-pageSizeOptions {
            margin-top: 0 !important;
        }
    }
}
